import React,{useState,useContext} from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { X } from "lucide-react";
import SnackBar from "../utils/SnackBar";
import { EmailValidation,EmptyFieldValidation } from "../Functions/Validations";
import TextInput from "../utils/TextInput";
import LoadingSpinner from "../utils/LoadingSpinner";
import Pressable from "../utils/Pressable";
import { UserContext } from "../App";
import { loginUser } from '../api/authApi';
import { storeUserData } from '../localuserdata/indexedDB';
import { encrypt } from '../Functions/encryption';

const LoginForm = () => {
  const {setUser} = useContext(UserContext);
  const Navigate = useNavigate();
  const [isLoading,setIsLoading]=useState(false);
  const [isSnackBarVisibale,setSnackbarVisible]=useState(false);
  const [snackMessage,setSnackMessage]=useState("");
  const [details,setDetails]=useState({
    email:"",
    password:""
   })
   function handleDetails(event) {
    const InputName = event.target.name;
    const NewValue = event.target.value;
    setDetails((prevValue) => {
      return {
        ...prevValue,
        [InputName]: NewValue,
      };
    });
  }
  const handleUserSignin = async () => {
    const { email, password } = details;
    try {
      setIsLoading(true);
      await EmptyFieldValidation(["email", "password"], details);
      await EmailValidation(email);
      
      const result = await loginUser(email, password);
      const {accessToken, refreshToken, uid, sessionId} = result;

      // Create userData with encrypted access token
      const userData = {
        refreshToken,
        uid,
        sessionId,
        accessToken: encrypt(accessToken) // Encrypt access token
      };
      await storeUserData(userData);
      setUser(userData.uid);
      
      // Show success message before navigation
      setSnackbarVisible(true);
      setSnackMessage("Login successful!");
      
      setTimeout(() => {
        Navigate('/ask-vetiq', { replace: true });
      }, 1000); 

    } catch (err) {
      if (err.message === "Email Not Verified! Please Verify Your Email Before Logging In!!!") {
        setSnackbarVisible(true);
        setSnackMessage("Email verification required. Redirecting...");
        
        const {accessToken, refreshToken, uid, sessionId} = err.userData;
        const verificationUserData = {
            refreshToken,
            uid,
            sessionId,
            accessToken: encrypt(accessToken)
        };
        
        setTimeout(() => {
            Navigate("/verify-new-user", {
                replace: true,
                state: {
                    nextScreen: "/ask-vetiq",
                    email: email,
                    uid: err.uid,
                    userData: verificationUserData
                }
            });
        }, 1500);
        return;
      }
      
      // Show error message
      setSnackbarVisible(true);
      setSnackMessage(err.message);
    } finally {
      setIsLoading(false);
      // Clear snackbar after delay
      setTimeout(() => {
        setSnackbarVisible(false);
        setSnackMessage("");
      }, 2000);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      {/* Loading Overlay */}
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <LoadingSpinner className="h-12 w-12 animate-spin" />
          </div>
      )}

      {/* Snackbar */}
      <SnackBar 
        isSnackBarVisibale={isSnackBarVisibale} 
        snackMessage={snackMessage}
      />

      <div className="w-full md:w-2/4 bg-white shadow-2xl p-6 rounded-t-2xl md:rounded-lg fixed bottom-0 md:static md:transform-none transition-transform ease-in-out duration-300 h-4/5 md:h-auto">
        {/* Close Button */}
        <div className="flex justify-end">
          <button 
            onClick={() => Navigate('/')} 
            className="text-gray-700"
            disabled={isLoading}
          >
            <X size={24} />
          </button>
        </div>
        {/* Login Header */}
        <div className="pb-2 text-center">
          <h2 className="text-3xl font-bold text-gray-800">Sign In</h2>
          <p className="text-lg mt-2 text-gray-600">
            First time here? <Link to="/signup" className="text-blue-500 hover:underline font-medium">Register now</Link>
          </p>
        </div>

        {/* Login Form */}
        <div className="mt-6 items-center justify-center">
          <TextInput
            required
            value={details.email}
            onChange={handleDetails}
            name="email"
            label="Email Address*"
            type="email"
            placeholder="Email"
            className="w-full p-3 border rounded mb-4 outline-none"
            disabled={isLoading}
          />
          <TextInput
            required
            value={details.password}
            onChange={handleDetails}
            name="password"
            label="Password*"
            type="password"
            placeholder="Password"
            className="w-full p-3 border rounded mb-4 outline-none"
            disabled={isLoading}
          />
        </div>
        <div className="flex items-center justify-center">
          <Pressable
            onClick={handleUserSignin}
            title={isLoading ? "Please wait..." : "LogIn"}
            disabled={isLoading}
            className={`w-full md:w-1/2 mt-4 ${
              isLoading ? 'bg-gray-400' : 'bg-[#7F55E0] hover:bg-[#6A44C0]'
            } justify-center text-xl text-white py-3 px-6 rounded-md transition duration-300`}
          />
        </div>
        <Link 
          to="/forgot-password" 
          className={`font-medium text-blue-500 text-lg mt-4 block text-center hover:underline ${
            isLoading ? 'pointer-events-none opacity-50' : ''
          }`}
        >
          Forgot Your Password?
        </Link>
      </div>
    </div>
  );
};

export default LoginForm;
