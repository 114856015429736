import React, { useContext, useState } from "react";
import { Menu, X, Home, Star, HandCoins, ScanHeart } from "lucide-react";
import Drawer from "../utils/CustomeDrawerContent";
import PoodlesLogo from "../utils/PoodlesLogo";
import { Link,NavLink } from "react-router-dom";
import { UserContext } from "../App";
import { deleteUserData } from '../localuserdata/indexedDB';
import { logoutUser } from '../api/authApi';
import { getUserData } from '../localuserdata/indexedDB';

const Header = ({ isOpen, setIsOpen }) => {
  const { User, setUser } = useContext(UserContext);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);
      const userData = await getUserData();
      if (userData) {
        await logoutUser(userData.uid, userData.sessionId);
      }
      await deleteUserData();
      setUser(null);
    } catch (error) {
      console.error('Logout error:', error);
      await deleteUserData();
      setUser(null);
    } finally {
      setIsLoggingOut(false);
    }
  };

  return (
    <header className="w-full bg-white shadow-sm p-2 fixed top-0 z-50 ">
      <div className=" md:w-3/4 mx-auto flex justify-between items-center">
        {/* Logo */}
        <div className="text-xl font-bold"> <Link to="/"><PoodlesLogo width={120} height={40} /></Link></div>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex gap-6">
          <NavLink
            to="/"
            className={({ isActive }) => isActive ? "text-[#7F55E0] flex flex-col items-center gap" : "hover:text-gray-500 flex flex-col items-center gap"}
          >
            <Home size={28} /> Home
          </NavLink>

          <NavLink
            to="/why-pet-preventive-care"
            className={({ isActive }) => isActive ? "text-[#7F55E0] flex flex-col items-center gap" : "hover:text-gray-500 flex flex-col items-center gap"}
          >
            <ScanHeart size={28} /> Why Preventive Care?
          </NavLink>

          <NavLink
            to="/upgrade-plan"
            className={({ isActive }) => isActive ? "text-[#7F55E0] flex flex-col items-center gap" : "hover:text-gray-500 flex flex-col items-center gap"}
          >
            <HandCoins size={28} /> Upgrade
          </NavLink>
        </nav>

        {/* Desktop Login button */}
        <div className="hidden md:flex gap-6">
          {User ?
            <button
              onClick={handleLogout}
              disabled={isLoggingOut}
              className="bg-[#323842] text-white px-8 py-2 rounded-md hover:bg-[#7F55E0] disabled:opacity-50"
            >
              {isLoggingOut ? 'Logging out...' : 'Logout'}
            </button>
            :
            <>
              <Link to="/login" className="bg-[#323842] text-white px-8 py-2 rounded-md hover:bg-[#7F55E0]">LogIn</Link>
              <Link to="/signup" className="bg-[#7F55E0] text-white px-8 py-2 rounded-md">Sign Up</Link></>
          }

        </div>

        {/* Mobile Menu button */}
        <div className="md:hidden">
          {!isOpen && (
            <button onClick={() => setIsOpen(true)}>
              <Menu size={24} />
            </button>
          )}
        </div>
      </div>
      {/* Drawer Component */}
      {isOpen && <Drawer User={User} setIsOpen={setIsOpen} isOpen={isOpen} />}


    </header>
  );
};
export default Header;