import { BASE_URL, API_ENDPOINTS, getHeaders } from './config';

export const loginUser = async (email, password) => {
  try {
    const response = await fetch(`${BASE_URL}${API_ENDPOINTS.AUTH.LOGIN}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, password }),
    });

    const result = await response.json();

    if (!response.ok) {
      // For unverified email, include the user data in the error
      if (result.error === "Email Not Verified! Please Verify Your Email Before Logging In!!!") {
        const error = new Error(result.error);
        error.uid = result.uid;
        error.userData = {
          email,  // Add email to userData since we need it
          ...result.userData // Contains uid, accessToken, refreshToken, sessionId
        };
        throw error;
      }
      throw new Error(result?.error || 'Login failed');
    }

    return result;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const signupUser = async (fullname, email, mobile, password) => {
  try {
    const response = await fetch(`${BASE_URL}${API_ENDPOINTS.AUTH.SIGNUP}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'  // Ensure correct headers
      },
      body: JSON.stringify({fullname, email, mobile, password})
    });


    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.error || 'Signup failed');
    }

    return await response.json();
  } catch (error) {
    console.error('Signup error:', error);
    throw error;
  }
};

export const sendEmailVerificationOtp = async (email,uid,verification_mode,verification_title,subject) => {
  try {
    const response = await fetch(`${BASE_URL}${API_ENDPOINTS.AUTH.SEND_OTP}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'  // Ensure correct headers
      },
      body: JSON.stringify({
        uid,
        email,
        verification_mode,
        verification_title,
        subject
      })
    });


    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.error || 'Failed to send email verification OTP');
    }

    return await response.json();
  } catch (error) {
    console.error('Send email verification OTP error:', error);
    throw error;
  }
};

export const verifyEmailOtp = async (uid, otp) => {
  try {
    const response = await fetch(
      `${BASE_URL}${API_ENDPOINTS.AUTH.VERIFY_OTP}?uid=${uid}&userotp=${parseInt(otp)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'  // Ensure correct headers
        },
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.error || 'Email verification failed');
    }

    return await response.json();
  } catch (error) {
    console.error('Verify email OTP error:', error);
    throw error;
  }
};

export const logoutUser = async (uid, sessionId) => {
  try {
    const response = await fetch(`${BASE_URL}${API_ENDPOINTS.AUTH.LOGOUT}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'  // Ensure correct headers
      },
      body: JSON.stringify({ uid, sessionId })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.error || 'Logout failed');
    }

    const result = await response.json();
    return result.loggedOut;
  } catch (error) {
    console.error('Logout error:', error);
    throw error;
  }
};

export const resetPassword = async (uid, password) => {
  try {
    const response = await fetch(`${BASE_URL}${API_ENDPOINTS.AUTH.RESET_PASSWORD}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'  // Ensure correct headers
      },
      body: JSON.stringify({ uid, password })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.error || 'Password reset failed');
    }

    return await response.json();
  } catch (error) {
    console.error('Reset password error:', error);
    throw error;
  }
};

export const checkEmailExists = async (email ) => {
    try {
        const response = await fetch(`${BASE_URL}${API_ENDPOINTS.AUTH.CHECK_EMAIL_EXISTS}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Ensure correct headers

            },
            body: JSON.stringify({ email }), // Send the email in the request body
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData?.error || 'Failed to check user for forgot password');
        }

        return await response.json(); // Assuming the response contains the user's UID
    } catch (error) {
        console.error('Check user for forgot password error:', error);
        throw error;
    }
}; 