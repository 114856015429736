import React from 'react';
 function LoadingSpinner({className}){
    return(
        <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 50 50"
        xmlSpace="preserve"
        width="100"
        height="100"
        className={className}
      >
        <circle
          fill="none"
          stroke="#7F55E0"
          strokeWidth="5"
          strokeLinecap="round"
          cx="25"
          cy="25"
          r="20"
          strokeDasharray="125.663706 125.663706"
          strokeDashoffset="0"
        >
          <animate
            attributeName="stroke-dashoffset"
            dur="1.5s"
            repeatCount="indefinite"
            values="0;251.32741;0"
          />
        </circle>
      </svg>
    )
 }
 export default LoadingSpinner;
