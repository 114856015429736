import React from "react";

const TextInput = ({label,name,type,inputMode, placeholder,value,onChange,className,id,required,pattern,onBlur,style,checked,readOnly,maxLength,autoFocus,wrap,rows,cols,}) => {
  return (
    <div className="flex flex-col">
      {label && (
        <label htmlFor={id || name} className="mb-1 font-medium text-gray-700">
          {label}
        </label>
      )}
      <input id={id} inputMode={inputMode}name={name}type={type}placeholder={placeholder}value={value}onChange={onChange} className={className}required={required}pattern={pattern}onBlur={onBlur}
        style={style}maxLength={maxLength}readOnly={readOnly}checked={checked}autoFocus={autoFocus}wrap={wrap}rows={rows}cols={cols}/>
    </div>
  );
};

const FileField = React.forwardRef(({ name, type, accept, onChange, className, id, style }, ref) => (
  <input ref={ref} style={style}name={name}type={type} multiple accept={accept} onChange={onChange}className={className}id={id}  />
));
export default TextInput
export {  FileField }

