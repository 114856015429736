import React, { useState, useRef } from "react";
import { X } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";
import TextInput from "../utils/TextInput";
import Pressable from "../utils/Pressable";
import LoadingSpinner from "../utils/LoadingSpinner";
import SnackBar from "../utils/SnackBar";
import { EmptyFieldValidation, PasswordValidation } from '../Functions/Validations';
import { resetPassword } from '../api/authApi';
const ResetPassword = () => {
    const Navigate = useNavigate();
    const location = useLocation();
    const { uid } = location.state
    const [details, setDetails] = useState({ password: "", confirmpassword: "" })
    const [isLoading, setIsLoading] = useState(false);
    const [isSnackBarVisibale, setSnackBarVisible] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const handleDetails = (event) => {
        const InputName = event.target.name;
        const NewValue = event.target.value;
        setDetails((prevValue) => {
            return {
                ...prevValue,
                [InputName]: NewValue,
            };
        });
    }
    const handleSubmit = async () => {
        const requiredFields = ["password", "confirmpassword"];
        const { password, confirmpassword } = details
        try {
            setIsLoading(true)
            await EmptyFieldValidation(requiredFields, { password, confirmpassword });
            await PasswordValidation(password);
            if (password !== confirmpassword) {
                throw new Error("Confirm Password Does not Match!!");
            }
            
            await resetPassword(uid, password);
            setSnackBarVisible(true);
            setSnackMessage('Password reset successfully!');
            setTimeout(() => {
                setSnackBarVisible(false);
                setSnackMessage('');
            }, 2000);
            Navigate('/login', { replace: true })

        } catch (error) {
            setSnackBarVisible(true);
            setSnackMessage(error.message);
            setTimeout(() => {
                setSnackBarVisible(false);
                setSnackMessage('');
            }, 2000);
        }
    };
    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
            <SnackBar isSnackBarVisibale={isSnackBarVisibale} snackMessage={snackMessage} />
            <div className="w-full md:w-1/4 bg-white shadow-xl p-6 rounded-t-2xl md:rounded-lg fixed bottom-0 md:static md:transform-none transition-transform ease-in-out duration-300 h-3/5 md:h-auto">
            {isLoading &&
                    <div className=" absolute z-50 p-5 top-1/4 left-1/2 -translate-x-1/2 -translate-y-1/2">
                        <LoadingSpinner className={"h-12 w-12 animate-spin z-50 "} />
                    </div>
                }
                <div className="flex justify-end">
                    <button onClick={() => { Navigate('/login') }} className="text-gray-700">
                        <X size={24} />
                    </button>
                </div>

                {/* Login Header */}
                <div className="pb-2 text-left">
                    <h2 className="text-2xl font-bold  justify-center gap-2">Set New Password.</h2>
                    <p className="text-lg  mt-2 text-stone-700">First time here?<a href="#"> You'll need to register. </a></p>
                </div>
                {/* Login Form Body */}
                <div className="mt-4">
                    <TextInput required value={details.password} onChange={handleDetails} name="password" label="Password*" type="password" placeholder="Password" className="w-full p-3 border rounded mb-4 outline-none" />
                    <TextInput required value={details.confirmpassword} onChange={handleDetails} name="confirmpassword" label="Confirm Password*" type="password" placeholder="Password" className="w-full p-3 border rounded mb-4 outline-none" />
                    <Pressable onClick={handleSubmit} title="Submit" className="w-full md:w-1/2 mt-4 bg-[#7F55E0] text-xl text-white py-3 px-6 rounded-md transition duration-300 hover:bg-[#6A44C0]"/>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
