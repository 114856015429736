import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { verifyPayment, updateSubscription } from '../api/paymentApi';
import LoadingSpinner from '../utils/LoadingSpinner';

const PaymentStatus = () => {
    const { uid,merchantTransactionId } = useParams();
    const navigate = useNavigate();
    const [status, setStatus] = useState('processing');
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const checkPaymentStatus = async () => {
            if (!merchantTransactionId) {
                setStatus('failed');
                setErrorMessage('Invalid transaction ID');
                setIsLoading(false);
                return;
            }

            try {
                const result = await verifyPayment(merchantTransactionId);
                
                if (result.success && result.state === "COMPLETED") {
                    await updateSubscription(uid, 8000, 'premium'); // Example values
                    setStatus('success');
                    navigate('/ask-vetiq');
                } else {
                    setStatus('failed');
                    setErrorMessage(result.message || 'Payment verification failed');
                    navigate('/upgrade-plan');
                }
            } catch (error) {
                setStatus('failed');
                setErrorMessage(error.message || 'Payment verification failed');
                navigate('/upgrade-plan');
            } finally {
                setIsLoading(false);
            }
        };

        checkPaymentStatus();
    }, [merchantTransactionId]);

    if (isLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <LoadingSpinner className="h-12 w-12 animate-spin" />
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center justify-center min-h-screen p-4">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
                {status === 'success' ? (
                    <>
                        <div className="mb-4 text-green-500">
                            <svg className="w-16 h-16 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                        <h2 className="text-2xl font-bold text-green-600 mb-4">
                            Payment Successful!
                        </h2>
                        <p className="text-gray-600 mb-6">
                            Your payment has been processed successfully. You can now access premium features.
                        </p>
                        <button
                            onClick={() => navigate('/ask-vetiq')}
                            className="bg-[#7F55E0] text-white px-6 py-2 rounded-md hover:bg-[#6A44C0]"
                        >
                            Continue to VetiQ
                        </button>
                    </>
                ) : (
                    <>
                        <div className="mb-4 text-red-500">
                            <svg className="w-16 h-16 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        <h2 className="text-2xl font-bold text-red-600 mb-4">
                            Payment Failed
                        </h2>
                        <p className="text-gray-600 mb-6">
                            {errorMessage || "We couldn't process your payment. Please try again."}
                        </p>
                        <button
                            onClick={() => navigate('/upgrade-plan')}
                            className="bg-[#7F55E0] text-white px-6 py-2 rounded-md hover:bg-[#6A44C0]"
                        >
                            Try Again
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default PaymentStatus;