import React from "react";
import PoodlesLogo from "../utils/PoodlesLogo";
import SocialIcons from "../utils/SocialIcons";
import { Link } from "react-router-dom";

const Footer = ({}) => {
    return (
        <footer   className="mt-auto w-full bg-white shadow-sm p-4">
            {/* Main Footer Wrapper */}
            <div className="flex flex-col md:w-3/4 mx-auto justify-between items-center gap-2">

                {/* First Section: Logo & Content */}
                <div className="flex flex-col md:flex-row items-center justify-between gap-4 w-full">
                    {/* Content Section */}
                    <div className="md:w-1/2 text-left space-y-2">
                        <h2 className="text-2xl md:text-5xl font-bold text-slate-900">
                            Prioritize Your Furry Friend’s Health
                        </h2>
                        <p className="text-md font-regular text-gray-700">
                            With VetiQ's proactive care, prevent costly treatments by staying ahead of health issues and enhancing your pet's well-being. Get VetiQ today for accurate health advice and early risk detection!
                        </p>
                    </div>
                </div>

                {/* Second Section: Menu & Social Links */}
                <div className="bg-slate-50 p-4 rounded-md flex flex-col md:flex-row justify-between items-left md:items-center w-full gap-2">
                    <PoodlesLogo width={140} height={40} />
                    {/* Menu Items */}
                    <div className="flex flex-col md:flex-row  gap-2 text-gray-700 text-lg">
                        <Link to="/" className="text-md font-regular hover:text-indigo-600 transition">Home</Link>
                        <Link to="/login" className="text-md font-regular hover:text-indigo-600 transition">Login</Link>
                        <Link to="/signup" className="text-md font-regular hover:text-indigo-600 transition">Join Our Community</Link>
                        <Link to="/why-pet-prevantive-care" className="text-md font-regular hover:text-indigo-600 transition">Why Prevantive Care?</Link>
                        <Link to="/upgrade-plan" className="text-md font-regular hover:text-indigo-600 transition">Upgrade</Link>
                        <Link to="/privacy-policy" className="text-md font-regular hover:text-indigo-600 transition">Privacy Policy</Link>
                        <Link to="/terms-of-use" className="text-md font-regular hover:text-indigo-600 transition">Terms & Condition</Link>
                    </div>
                        <SocialIcons />
                </div>

            </div>
        </footer>
    );
};

export default Footer;
