import React, { useState, useRef, useContext, useEffect } from "react";
import { Send, Dog, Cat } from "lucide-react";
import Pressable from "../utils/Pressable";
import AddNewPetModal from '../Component/AddNewPetModal';
import { getAIResponse } from "../api/aiApi";
import { getAllPetProfiles } from "../api/petApi";
import { UserContext } from "../App";

const CreditsSection = ({ remainingCredits, onAddPet }) => (
  <div className="flex justify-between items-center mb-4">
    <Pressable onClick={onAddPet} title="Add Pet" className="text-xl font-semibold" />
  </div>
);

const PetCard = ({ pet, isSelected, onClick }) => (
  <div
    onClick={onClick}
    className={`flex flex-col items-center justify-center gap-2 p-2 rounded-md cursor-pointer transition-all ${
      isSelected
        ? 'border-2 border-blue-500'
        : 'bg-white border border-gray-200 hover:border-purple-300'
    }`}
    style={{ width: '80px', height: '80px' }}
  >
    <div className="flex items-center justify-center w-full h-full">
      {pet.pettype?.toLowerCase() === 'dog' ? (
        <Dog size={32} />
      ) : (
        <Cat size={32} />
      )}

    </div>
    <p className="font-semibold text-slate-900 text-center">{pet.petname}</p>
  </div>
);

const PetSelector = ({ pets, selectedPetId, onPetSelect }) => {
  const sortedPets = [...pets].sort((a, b) => (a.petProfileId === selectedPetId ? -1 : 1));

  return (
    <div className="flex gap-2 overflow-x-auto pb-2">
      {sortedPets.map((pet) => (
        <PetCard
          key={pet.petProfileId}
          pet={pet}
          isSelected={selectedPetId === pet.petProfileId}
          onClick={() => onPetSelect(pet.petProfileId)}
        />
      ))}
    </div>
  );
};

const Button = ({ children, onClick, disabled }) => (
  <button 
    className={`p-2 rounded-full shadow-md transition duration-300 ${
      disabled 
        ? 'bg-gray-300 cursor-not-allowed' 
        : 'bg-[#7F55E0] hover:bg-[#6A44C0]'
    }`}
    onClick={onClick}
    disabled={disabled}
  >
    <div className="text-white">
      {children}
    </div>
  </button>
);

const ThirdBottom = ({ question, setQuestion, onSubmit, disabled }) => (
  <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full max-w-6xl bg-white p-3 shadow-md flex items-center rounded-lg border border-gray-300">
    <textarea 
      className="w-full p-2 focus:outline-none resize-none overflow-hidden" 
      placeholder={disabled ? "Please select a pet first..." : "Ask a question..."}
      value={question}
      onChange={(e) => setQuestion(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !e.shiftKey && !disabled) {
          e.preventDefault();
          onSubmit();
        }
      }}
      disabled={disabled}
    ></textarea>
    <Button onClick={onSubmit} disabled={disabled}>
      <Send size={20} />
    </Button>
  </div>
);

const SecondMiddle = ({chatData}) => (
  <div className="w-full flex-1 pb-20">
    <div className="flex flex-col space-y-4">
      {chatData.map((item, index) => (
        <div
          key={index}
          className={`flex ${
            item.type === "question" ? "justify-start" : "justify-end"
          }`}
        >
          <div
            className={`max-w-[94%] md:max-w-[70%] p-4 rounded-2xl shadow-sm ${
              item.type === "question"
                ? "bg-[#E3F2FD] text-slate-800 rounded-tl-none"
                : "bg-[#C8E6C9] border border-[#AED581] text-slate-800 rounded-tr-none"
            }`}
          >
            <p className="text-[18px] md:text-base font-[DMSans-Regular] leading-relaxed whitespace-pre-wrap">
              {item.text}
            </p>
            <span className="text-xs mt-1 text-slate-500 block text-right">
              {new Date().toLocaleTimeString([], { 
                hour: '2-digit', 
                minute: '2-digit'
              })}
            </span>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const AskVetiQ = () => {
  const { User } = useContext(UserContext);
  const [addNewPet, setAddNewPet] = useState(false);
  const [question, setQuestion] = useState("");
  const [chatData, setChatData] = useState([]);
  const [allPets, setAllPets] = useState([]);
  const [selectedPetId, setSelectedPetId] = useState(null);
  const [remainingCredits, setRemainingCredits] = useState(0);
  const scrollViewRef = useRef(null);
  const [petAdded, setPetAdded] = useState(false);

  const fetchPetsData = async () => {
    try {
      const data = await getAllPetProfiles(User);
      if (data && data.length > 0) {
        setAllPets(data);
        setSelectedPetId(data[0].petProfileId);
      }
    } catch (error) {
      console.error('Failed to fetch pets:', error);
    }
  };

  useEffect(() => {
    fetchPetsData();
  }, [User]);

  useEffect(() => {
    if (petAdded) {
      fetchPetsData();
      setPetAdded(false);
    }
  }, [petAdded]);

  const selectedPet = allPets.find(pet => pet.petProfileId === selectedPetId);

  const scrollToBottom = () => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight;
    }
  };

  const handleSavePet = (petData) => {
    setAddNewPet(false);
    setPetAdded(true);
  };

  const startTheChat = async () => {
    try {
      const newChatData = [
        ...chatData,
        { type: "question", text: question },
        { type: "answer", text: "" },
      ];

      setChatData(newChatData);
      setQuestion("");

      setTimeout(scrollToBottom, 100);

      const result = await getAIResponse(question, selectedPet, chatData, User);
      const { text, remainingCredits } = result;

      let chunkedText = '';
      const chunkSize = 50;

      for (let i = 0; i < text.length; i += chunkSize) {
        chunkedText += text.slice(i, i + chunkSize);

        setChatData((prevChatData) => {
          const updatedChatData = [...prevChatData];
          updatedChatData[updatedChatData.length - 1] = {
            ...updatedChatData[updatedChatData.length - 1],
            text: chunkedText,
          };
          return updatedChatData;
        });

        await new Promise(resolve => setTimeout(resolve, 100));
        setTimeout(scrollToBottom, 100);
      }

      setRemainingCredits(remainingCredits);
    } catch (err) {
      console.error(err.message);
      return "An error occurred while fetching the response.";
    }
  };

  return (
    <div className="flex flex-col items-center p-4 pt-20 space-y-8 bg-slate-50 min-h-screen relative">
      <div className="flex flex-col md:flex-col items-center max-w-6xl w-full h-[calc(100vh-160px)] overflow-y-auto" ref={scrollViewRef}>
        <div className="w-full pb-5">
          <CreditsSection 
            remainingCredits={remainingCredits} 
            onAddPet={() => setAddNewPet(true)} 
          />
          <PetSelector 
            pets={allPets} 
            selectedPetId={selectedPetId} 
            onPetSelect={setSelectedPetId} 
          />
        </div>

        <SecondMiddle chatData={chatData} />
        
        {addNewPet && (
          <AddNewPetModal uid={User} onClose={() => setAddNewPet(false)} onPetAdded={handleSavePet} />
        )}
      </div>

      <ThirdBottom 
        question={question} 
        setQuestion={setQuestion} 
        onSubmit={startTheChat}
        disabled={!selectedPetId} 
      />
    </div>
  );
};

export default AskVetiQ;

