import React, { useState, useEffect } from "react";
import Pressable from "../utils/Pressable";
const VerifyOtp = ({ SendVerificationEmail,inputRefs,setOtp,otp,resendEnabled,setResendEnabled,heading,subheading,handleSubmit }) => {
    const [timer, setTimer] = useState(30); // 30 seconds countdown
       // Timer logic
       useEffect(() => {
         if (timer > 0) {
           const countdown = setTimeout(() => setTimer((prev) => prev - 1), 1000);
           return () => clearTimeout(countdown); // Clean up
         } else {
           setResendEnabled(true); // Enable resend button after timer ends
         }
       }, [timer]);
   
       const handleResend = () => {
           setTimer(30); // Reset timer to 30 seconds
           setResendEnabled(false); // Disable resend button
           // Add resend OTP logic here
           SendVerificationEmail()
         };
       

    const handleChange = (index, value) => {
        // Allow only numeric input and ensure it's a single character
        if (/[^0-9]/.test(value)) return; // Reject non-numeric values
        if (value.length > 1) return; // Allow only a single character

        // Update OTP string
        const newOtp = otp.split(""); // Convert OTP string to an array of characters
        newOtp[index] = value; // Update the character at the specified index
        setOtp(newOtp.join("")); // Join it back to a string

        // Focus next input field if value is entered
        if (value.length >= 1 && index < 5) {
            inputRefs.current[index + 1]?.focus(); // Focus on the next input
        }
    };
    const handleBackPress = (index, e) => {
        if (e.key === "Backspace" && index > 0) {
            if (index > 0) {
                inputRefs.current[index - 1]?.focus();
            }
        }
    }

    return (
        <div className="text-center">
            <h2 className="text-2xl font-bold text-[#4A2E8C] mb-4">{heading}</h2>
            <p className="text-gray-600 mb-6 text-center">{subheading}</p>
            <div className="flex space-x-2 mb-6 justify-center">
                {Array.from({ length: 6 }).map((_, index) => (
                    <input
                        key={index}
                        type="text" // Set type to text, not number
                        value={otp[index] || ""}
                        onChange={(e) => handleChange(index, e.target.value)} // Handle change
                        ref={(ref) => (inputRefs.current[index] = ref)} // Assign the ref
                        maxLength="1" // Max length set to 1
                        className="w-12 h-12 text-center text-xl font-semibold border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#7F55E0]"
                        inputMode="numeric" // Hint for numeric input
                        pattern="[0-9]*" // Prevent non-numeric input on mobile
                        onInput={(e) => e.preventDefault()}
                        onKeyDown={(e) => handleBackPress(index, e)}
                    />
                ))}
            </div>
            <Pressable onClick={handleSubmit} title="Submit" className="w-full md:w-1/2 mt-4 bg-[#7F55E0] text-xl text-white py-3 px-6 rounded-md transition duration-300 hover:bg-[#6A44C0]"/>
            {resendEnabled ? (
            <Pressable onClick={handleResend} title="Didn't Get A Code? Resend" className=" text-xl   hover:bg-[#6A44C0]"/>
        ) : (
          <p className="text-gray-600 mb-6 text-center">
            Resend OTP in {timer}s
          </p>
        )}
         <p className="text-gray-600 mb-6 text-center">Please Do Not Press Back Or Close This!</p>
        </div>
    );
};

export default VerifyOtp;
