import React, { useState, useRef } from "react";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { EmptyFieldValidation, EmailValidation } from "../Functions/Validations";
import TextInput from "../utils/TextInput";
import Pressable from "../utils/Pressable";
import { sendEmailVerificationOtp, checkEmailExists } from '../api/authApi';
import LoadingSpinner from '../utils/LoadingSpinner';
import SnackBar from '../utils/SnackBar';

const ForgotPassword = () => {
    const Navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isSnackBarVisibale, setSnackBarVisible] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [email, setEmail] = useState("");

    const handleSubmit = async () => {
        const requiredFields = ["email"];
        setIsLoading(true)
        try {
            await EmptyFieldValidation(requiredFields, { email });
            await EmailValidation(email);
            const userCheckResponse = await checkEmailExists(email);
            const uid = userCheckResponse.uid;
            setIsLoading(false);
            Navigate("/verify-for-pass-req", {
                replace: true,
                state: {
                    nextScreen: "/reset-password",
                    email: email,
                    uid: userCheckResponse.uid
                },
            });
        } catch (err) {
            setIsLoading(false);
            setSnackBarVisible(true);
            setSnackMessage(err.message);
            setTimeout(() => {
                setSnackBarVisible(false);
                setSnackMessage('');
            }, 2000);
        } 
    }

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
            <SnackBar isSnackBarVisibale={isSnackBarVisibale} snackMessage={snackMessage} />
            <div className="w-full md:w-1/4 bg-white shadow-xl p-6 rounded-t-2xl md:rounded-lg fixed bottom-0 md:static md:transform-none transition-transform ease-in-out duration-300 h-3/5 md:h-auto">
                {isLoading &&
                    <div className=" absolute z-50 p-5 top-1/4 left-1/2 -translate-x-1/2 -translate-y-1/2">
                        <LoadingSpinner className={"h-12 w-12 animate-spin z-50 "} />
                    </div>
                }
                {/* Close Button - Aligned to Right */}
                <div className="flex justify-end">
                    <button onClick={() => { Navigate('/login') }} className="text-gray-700">
                        <X size={24} />
                    </button>
                </div>

                {/* Login Header */}
                <div className="pb-2 text-left">
                    <h2 className="text-2xl font-bold  justify-center gap-2">Forgot Your Password.</h2>
                    <p className="text-lg  mt-2 text-stone-700">Please Provide Your Registered Email*</p>
                </div>
                {/* Login Form Body */}
                <div className="mt-4 justify-center items-center">

                    <TextInput required value={email} onChange={(event) => setEmail(event.target.value)} name="email" label="Email Address*" type="email" placeholder="Email" className="w-full p-3 border rounded mb-4 outline-none" />
                    <Pressable onClick={handleSubmit} title="Send Verification Code" className="w-full md:w-full justify-center mt-4 bg-[#7F55E0] text-xl text-white py-3 px-6 rounded-md transition duration-300 hover:bg-[#6A44C0]" />
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
