const DB_NAME = 'VetiQDB';
const DB_VERSION = 1;
const USER_STORE = 'userCredentials';
const CURRENT_USER_KEY = 'currentUser';

// Initialize the database
const initDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = () => {
      reject(request.error);
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      
      // Create object store without keyPath
      if (!db.objectStoreNames.contains(USER_STORE)) {
        const store = db.createObjectStore(USER_STORE);
      }
    };
  });
};

// Store user data
export const storeUserData = async (userData) => {
  try {
    const db = await initDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([USER_STORE], 'readwrite');
      const store = transaction.objectStore(USER_STORE);
      
      // Store with fixed key
      const request = store.put(userData, CURRENT_USER_KEY);
      
      request.onsuccess = () => resolve(true);
      request.onerror = () => reject(request.error);
    });
  } catch (error) {
    console.error('Error storing user data:', error);
    throw error;
  }
};

// Get user data
export const getUserData = async () => {
  try {
    const db = await initDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([USER_STORE], 'readonly');
      const store = transaction.objectStore(USER_STORE);
      
      // Get data using fixed key
      const request = store.get(CURRENT_USER_KEY);
      
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  } catch (error) {
    console.error('Error getting user data:', error);
    throw error;
  }
};

// Delete user data
export const deleteUserData = async () => {
  try {
    const db = await initDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([USER_STORE], 'readwrite');
      const store = transaction.objectStore(USER_STORE);
      
      // Delete using fixed key
      const request = store.delete(CURRENT_USER_KEY);
      
      request.onsuccess = () => resolve(true);
      request.onerror = () => reject(request.error);
    });
  } catch (error) {
    console.error('Error deleting user data:', error);
    throw error;
  }
};

// Update user data
export const updateUserData = async (userData) => {
  try {
    const db = await initDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([USER_STORE], 'readwrite');
      const store = transaction.objectStore(USER_STORE);
      
      const request = store.put(userData); // put will update if exists
      
      request.onsuccess = () => resolve(true);
      request.onerror = () => reject(request.error);
    });
  } catch (error) {
    console.error('Error updating user data:', error);
    throw error;
  }
};

// Check if user exists
export const checkUserExists = async (uid) => {
  try {
    const userData = await getUserData(uid);
    return !!userData;
  } catch (error) {
    console.error('Error checking user existence:', error);
    return false;
  }
}; 