import React, { useState, useEffect, createContext } from "react";
import { decrypt } from "./Functions/encryption";
import { BrowserRouter as Router } from "react-router-dom";
import AppNavigation from "./Navigation/AppNavigation";
import { getUserData, deleteUserData, storeUserData } from './localuserdata/indexedDB';
import { refreshUserToken } from './api/tokenApi';
import { jwtDecode } from "jwt-decode";
import Modal from "./utils/Modal"; // Assuming you have a Modal component
import { logoutUser } from './api/authApi';
import LoadingSpinner from "./utils/LoadingSpinner";
import { encrypt } from "./Functions/encryption";
export const UserContext = createContext();
function App() {
  const [User, setUser] = useState(null); // Will now only store uid
  const [isLoading, setIsLoading] = useState(true);
  const [showSessionModal, setShowSessionModal] = useState(false);

  const handleLogout = async () => {
    try {
      const userData = await getUserData(); // Get full user data for API call
      if (userData) {
        // Call logout API with required data
        await logoutUser(userData.uid, userData.sessionId);
      }
      // Clear local data
      await deleteUserData();
      setUser(null);
      setShowSessionModal(true);
    } catch (error) {
      console.error('Logout error:', error);
      // Still clear local data even if API call fails
      await deleteUserData();
      setUser(null);
    }
  };

  const isSessionExpiredError = (error) => {
    return error.message.includes("User Token Has Expired") ||
           error.message.includes("Refresh Token Did Not Match!") ||
           error.message.includes("Session expired.");
  };

  // Check token validity
  const isTokenValid = (token) => {
    try {
      const decodedToken = jwtDecode(token);  // Just decode, don't decrypt here
      const currentTime = Date.now() / 1000;
      return decodedToken.exp > currentTime;
    } catch (error) {
      console.error('Token validation error:', error);
      return false;
    }
  };

  const checkAndRefreshToken = async (userData) => {
    try {
      if (!userData || !userData.accessToken) {
        setUser(null);
        return false;
      }

      const decryptedToken = decrypt(userData.accessToken);
      if (isTokenValid(decryptedToken)) {  // Pass decrypted token
        setUser(userData.uid);
        return true;
      }

      // Token is invalid, try to refresh
      const refreshedData = await refreshUserToken(userData.refreshToken,userData.sessionId,userData.uid);
       const {accessToken, refreshToken, uid, sessionId} = refreshedData;
      
            // Create userData with encrypted access token
            const newuserData = {
              refreshToken,
              uid,
              sessionId,
              accessToken: encrypt(accessToken) // Encrypt access token
            };
      await storeUserData(newuserData);
      setUser(newuserData.uid);
      return true;
    } catch (refreshError) {
      if (isSessionExpiredError(refreshError)) {
        await handleLogout();
      } else {
        console.error('Token refresh failed');
        setUser(null);
      }
      return false;
    }
  };

  // Initial load and subsequent token checks
  

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        setIsLoading(true);
        const userData = await getUserData();
        await checkAndRefreshToken(userData);
      } catch (error) {
        console.error('Auth check failed');
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    };

    initializeAuth();

    // Set up periodic token refresh
    const refreshInterval = setInterval(async () => {
      try {
        const userData = await getUserData();
        if (userData) {
          await checkAndRefreshToken(userData);
        }
      } catch (error) {
        console.error('Periodic refresh failed');
        if (isSessionExpiredError(error)) {
          await handleLogout();
        }
      }
    }, 13800000); // 3 hours 50 minutes

    return () => clearInterval(refreshInterval);
  }, []); // Only run on mount

  const userContextValue = { 
    User, // Now just contains uid
    setUser,
    isAuthenticated: !!User
  };

  if (isLoading) {
    return <div className=" absolute z-50 p-5 top-1/4 left-1/2 -translate-x-1/2 -translate-y-1/2">
    <LoadingSpinner className={"h-12 w-12 animate-spin z-50 "} />
   </div>
  }

  return (
    <UserContext.Provider value={userContextValue}>
      <Router>
        <AppNavigation />
        {showSessionModal && (
          <Modal
            isOpen={showSessionModal}
            onClose={() => setShowSessionModal(false)}
            title="Session Expired"
            message="Your session has expired. Please log in again."
          />
        )}
      </Router>
    </UserContext.Provider>
  );
}

export default App;