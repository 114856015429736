import { getUserData } from '../localuserdata/indexedDB';

// API configuration and constants
export const BASE_URL = 'https://auth.poodles.in';
// export const BASE_URL = 'http://172.24.132.173:3000';
export const API_ENDPOINTS = {
  AUTH: {
    LOGIN: '/LoginUser/logInWithEmailAndPassword',
    SIGNUP: '/CreateUser/createUserWithEmailPassword',
    SEND_OTP: '/CreateUser/sendotp',
    VERIFY_OTP: '/CreateUser/EmailVerification',
    LOGOUT: '/LoginUser/logout',
    REFRESH_TOKEN: '/VerifyToken/refreshToken',
    CHECK_EMAIL_EXISTS: '/RecoverPassword/check_user_forgot_password',
    RESET_PASSWORD: '/RecoverPassword/UpdateUserPassword',
  },
  USER: {
    GET_PROFILE: '/user/profile',
    UPDATE_PROFILE: '/user/update',
  },
  PETS: {
    ADD: '/ManagePetProfile/AddNewPetProfile',
    GET_ALL: '/ManagePetProfile/GetAllPetProfiles?uid=${uid}',
  },
  PAYMENT: {
    INITIATE: '/api/payment/initiate',
    VERIFY: '/api/payment/verify',
    UPGRADE_PLAN: '/api/payment/update-subscription',
  }
};

// Common headers with async token fetch
export const getHeaders = async (includeAuth = false) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };

  if (includeAuth) {
    try {
      const userData = await getUserData();
      if (userData?.accessToken) {
        headers['Authorization'] = `Bearer ${userData.accessToken}`;
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }

  return headers;
}; 