import { BASE_URL, API_ENDPOINTS, getHeaders } from './config';

export const initiatePayment = async (orderId, amount, userId) => {
  try {
    const headers = await getHeaders(true);
    const response = await fetch(`${BASE_URL}${API_ENDPOINTS.PAYMENT.INITIATE}`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        orderId,
        amount,
        userId
      })
    });

    const data = await response.json();
    // console.log('Payment initiation response:', data); // Remove this line

    if (!response.ok) {
      throw new Error(data.message || 'An error occurred while processing your request. Please try again.');
    }

    // Match the backend response structure: { success: true, paymentUrl }
    if (data.success && data.paymentUrl) {
      return {
        success: true,
        paymentUrl: data.paymentUrl
      };
    } else {
      // console.error('Invalid response structure:', data); // Remove this line
      throw new Error('Invalid response format from payment service');
    }
  } catch (error) {
    // console.error('Payment verification error:', error); // Remove this line
    throw error;
  }
};

export const verifyPayment = async (merchantTransactionId) => {
  try {
    const headers = await getHeaders(true);
    const response = await fetch(
      `${BASE_URL}${API_ENDPOINTS.PAYMENT.VERIFY}?merchantTransactionId=${merchantTransactionId}`,
      {
        method: 'GET',
        headers
      }
    );

    const data = await response.json();
    // console.log('Payment verification response:', data); // Debug log

    if (!response.ok) {
      throw new Error(data.message || 'Payment verification failed');
    }

    // Match the backend response structure
    if (data.success && data.data?.state === "COMPLETED") {
      return {
        success: true,
        state: data.data.state,
        data: data.data
      };
    } else {
      throw new Error(data.message || 'Payment verification failed');
    }
  } catch (error) {
    // console.error('Payment verification error:', error);
    throw error;
  }
};

export const updateSubscription = async (uid, creditAmount, subscriptionMode) => {
  try {
    const headers = await getHeaders(true);
    const response = await fetch(`${BASE_URL}${API_ENDPOINTS.PAYMENT.UPGRADE_PLAN}`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ uid, creditAmount, subscriptionMode })
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Failed to update subscription');
    }

    return data;
  } catch (error) {
    // console.error('Error updating subscription:', error);
    throw error;
  }
}; 