import { BASE_URL } from './config';
export const getAIResponse = async (prompt, petDetail, chatHistory = [], uid) => {
  try {
    const response = await fetch(`${BASE_URL}/api/ai/generate`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        prompt,
        PetDetail: petDetail,
        chatHistory,
        uid
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.error || 'Failed to get AI response');
    }

    const result = await response.json();
    return {
      text: result.text,
      remainingCredits: result.remainingCredits
    };
  } catch (error) {
    console.error('AI response error:', error);
    throw error;
  }
};