import React from "react";

function SnackBar({ isSnackBarVisibale, snackMessage }) {
  const snackbarAnimationStyle = {
    transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
    opacity: isSnackBarVisibale ? 1 : 0,
    transform: isSnackBarVisibale ? "translateY(0)" : "translateY(20px)",
    visibility: isSnackBarVisibale ? "visible" : "hidden",
  };

  return (
    <div
      id="snackbar"
      style={snackbarAnimationStyle}
      className="w-full justify-center md:w-1/4 bg-black shadow-2xl p-4 rounded-md md:rounded-lg fixed bottom-10 md:h-auto z-50"
    >
      <p className="text-md text-center text-white break-words whitespace-pre-wrap">
        {snackMessage}
      </p>
    </div>
  );
}

export default SnackBar;
