import CryptoJS from 'crypto-js';

const key = process.env.REACT_APP_ENCRYPTION_KEY;

// Simple encrypt function that takes any data and returns encrypted string
export const encrypt = (data) => {
    try {
        const str = typeof data === 'string' ? data : JSON.stringify(data);
        return CryptoJS.AES.encrypt(str, key).toString();
    } catch {
        throw new Error('Encryption failed');
    }
};

// Simple decrypt function that takes encrypted string and returns original data
export const decrypt = (encrypted) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encrypted, key);
        const str = bytes.toString(CryptoJS.enc.Utf8);
        try {
            return JSON.parse(str); // Try to parse as JSON first
        } catch {
            return str; // If not JSON, return as string
        }
    } catch {
        throw new Error('Decryption failed');
    }
}; 