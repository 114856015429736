import React, { useState, useRef, useEffect } from "react";
import VerifyOtp from "../Component/VerifyOtp";
import { OtpValidation } from "../Functions/Validations";
import { useNavigate, useLocation } from "react-router-dom";
import { sendEmailVerificationOtp, verifyEmailOtp } from '../api/authApi';
import LoadingSpinner from '../utils/LoadingSpinner';
import SnackBar from '../utils/SnackBar';
const VerifyForgotPasswordPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isSnackBarVisibale, setSnackBarVisible] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [otp, setOtp] = useState("");
    const inputRefs = useRef([]);
    const { nextScreen, email, uid } = location.state;
    const [resendEnabled, setResendEnabled] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);

    const SendVerificationEmail = async () => {
        try {
            setIsLoading(true);
            const verification_title = 'forgot_password';
            const verification_mode = 'email';
            const subject = 'Verify Your Email For Poodles Petcare';
            await sendEmailVerificationOtp(email,uid,verification_mode,verification_title,subject);
            setSnackBarVisible(true);
            setSnackMessage('Verification email sent successfully!');

            setTimeout(() => {
                setSnackBarVisible(false);
                setSnackMessage('');
            }, 2000);
        } catch (err) {
            console.error('Failed to send verification email:', err);
            setSnackBarVisible(true);

            setSnackMessage(err.message);
            setTimeout(() => {
                setSnackBarVisible(false);
                setSnackMessage('');
            }, 2000);
        }

    };

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            await OtpValidation(otp);
            await verifyEmailOtp(uid, otp);
            setSnackBarVisible(true);
            setSnackMessage('OTP verified successfully!');
            setTimeout(() => {
                setSnackBarVisible(false);
                setSnackMessage('');
            }, 2000);
            navigate(nextScreen, {
                replace: true,
                state: {
                    uid: uid
                },
            });
        } catch (err) {
            console.error('OTP verification failed:', err);
            setSnackBarVisible(true);
            setSnackMessage(err.message);
            setTimeout(() => {
                setSnackBarVisible(false);
                setSnackMessage('');
            }, 2000);
        } finally {
            setIsLoading(false);
        }

    };

    useEffect(() => {
        if (!isEmailSent) {
            SendVerificationEmail();
            setIsEmailSent(true);
        }
    }, [isEmailSent]); 

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
            <SnackBar isSnackBarVisibale={isSnackBarVisibale} snackMessage={snackMessage} />
            <div className="w-full md:w-1/4 bg-white shadow-xl p-6 rounded-t-2xl md:rounded-lg fixed bottom-0 md:static md:transform-none transition-transform ease-in-out duration-300 h-3/5 md:h-auto">
                {isLoading &&
                    <div className=" absolute z-50 p-5 top-1/4 left-1/2 -translate-x-1/2 -translate-y-1/2">
                        <LoadingSpinner className={"h-12 w-12 animate-spin z-50 "} />
                    </div>
                }
                <VerifyOtp 
                    setOtp={setOtp} 
                    SendVerificationEmail={SendVerificationEmail} 
                    inputRefs={inputRefs} 


                    otp={otp} 
                    resendEnabled={resendEnabled} 
                    setResendEnabled={setResendEnabled} 
                    handleSubmit={handleSubmit} 
                    heading='Verify Your Email' 
                    subheading="Enter the OTP sent to your email to reset password." 
                />
            </div>
        </div>
    );
};

export default VerifyForgotPasswordPage;
