import React, { useState } from 'react';
import { Plus} from "lucide-react";

const faqData = [
  {
    question: "What is preventive care for pets?",
    answer: "Preventive care involves regular check-ups and vaccinations to catch illnesses early. This includes routine screenings, dental care, and maintaining a healthy diet and weight.",
  },
  {
    question: "Why is preventive care important for pets?",
    answer: "Preventive care helps detect health issues before they become serious, saving you from unexpected and costly treatments. It ensures your pet stays healthy and enjoys a better quality of life.",
  },
  {
    question: "How can preventive care save money?",
    answer: "By catching illnesses early, preventive care avoids expensive emergency treatments and late-stage interventions, ultimately reducing overall healthcare costs for your pet.",
  },
  {
    question: "What are common hidden dangers in pets?",
    answer: "Pets may appear healthy but could have underlying issues such as obesity, hidden infections, or early-stage diseases that can escalate if left untreated.",
  },
  {
    question: "What role does diet play in preventive care?",
    answer: "A well-balanced diet is crucial to prevent obesity and related conditions like diabetes. Monitoring your pet's diet and maintaining an appropriate weight are key aspects of preventive care.",
  },
  {
    question: "How often should I take my pet for preventive care check-ups?",
    answer: "It's recommended to schedule regular check-ups at least once a year. However, more frequent visits may be necessary depending on your pet's age, breed, and health condition.",
  },
];

function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="w-full p-2">
      <h2 className="text-xl md:text-2xl font-bold mb-4">Frequently Asked Questions</h2>
      {faqData.map((faq, index) => (
        <div key={index} className="mb-4 p-4  bg-gray-100 rounded-lg">
          <h3
            className="flex justify-between text-md md:text-lg font-semibold cursor-pointer mb-2"
            onClick={() => toggleFAQ(index)}
          >
            {faq.question}<Plus size={24} color="#323842" />
          </h3>
          {activeIndex === index && (
            <p className='text-lg'>{faq.answer}</p>
          )}
        </div>
      ))}
    </div>
  );
}

export default FAQ;
