import React, { useState, useRef, useEffect, useContext } from "react";
import VerifyOtp from "../Component/VerifyOtp";
import { OtpValidation } from "../Functions/Validations";
import { useNavigate, useLocation } from "react-router-dom";
import { sendEmailVerificationOtp, verifyEmailOtp } from '../api/authApi';
import { storeUserData } from '../localuserdata/indexedDB';
import { UserContext } from '../App';
import LoadingSpinner from '../utils/LoadingSpinner';
import SnackBar from '../utils/SnackBar';

const VerifyNewUserPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { setUser } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const inputRefs = useRef([]);
    const { nextScreen, email, uid, userData } = location.state;
    const [resendEnabled, setResendEnabled] = useState(false);
    const [isSnackBarVisibale, setSnackBarVisible] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [isEmailSent, setIsEmailSent] = useState(false);

    const showSnackbar = (message) => {
        setSnackMessage(message);
        setSnackBarVisible(true);
        setTimeout(() => {
            setSnackBarVisible(false);
            setSnackMessage('');
        }, 3000);
    };

    const SendVerificationEmail = async () => {
        try {
            const verification_mode = 'email';
            const verification_title = 'new_user_email_verification';
            const subject = 'Verify Your Email For Poodles Petcare';
            await sendEmailVerificationOtp(email,uid,verification_mode,verification_title,subject);
            setSnackBarVisible(true);
            setSnackMessage('Verification email sent successfully!');

             setTimeout(() => {
                setSnackBarVisible(false);
                setSnackMessage('');
            }, 2000);

        } catch (err) {
            console.error('Failed to send verification email:', err);
            showSnackbar('Failed to send verification email. Please try again.');
        } finally {
        }
    };

    const handleVerifyOtp = async () => {
        try {
            setIsLoading(true);
            await OtpValidation(otp);

            // Verify OTP first
            await verifyEmailOtp(uid, otp);

            // Store full user data in IndexedDB
            await storeUserData(userData);
            setUser(uid);
            setSnackBarVisible(true);
            setSnackMessage('Email verified successfully!');
             setTimeout(() => {
                setSnackBarVisible(false);
                setSnackMessage('');
            }, 2000);
            navigate(nextScreen, { replace: true })
        } catch (err) {
            console.error('OTP verification failed:', err);
            setSnackBarVisible(true);
            if (err.message.includes('Otp is invalid')) {
                setSnackMessage('Invalid OTP! Please enter the correct OTP.');
            } else if (err.message.includes('Otp has expired')) {

                setSnackMessage('OTP has expired! Please request a new OTP.');
            } else {

                setSnackMessage('OTP verification failed. Please check your OTP and try again.');
            }
            setTimeout(() => {
                setSnackBarVisible(false);
                setSnackMessage('');
            }, 2000);
        }
    };


    useEffect(() => {
        if (!isEmailSent) {
            SendVerificationEmail();
            setIsEmailSent(true);
        }
    }, [isEmailSent]); 

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
            <SnackBar isSnackBarVisibale={isSnackBarVisibale} snackMessage={snackMessage} />
            <div className="w-full md:w-1/4 bg-white shadow-xl p-6 rounded-t-2xl md:rounded-lg fixed bottom-0 md:static md:transform-none transition-transform ease-in-out duration-300 h-3/5 md:h-auto">
                {isLoading &&
                    <div className=" absolute z-50 p-5 top-1/4 left-1/2 -translate-x-1/2 -translate-y-1/2">
                        <LoadingSpinner className={"h-12 w-12 animate-spin z-50 "} />
                    </div>
                }
                <VerifyOtp
                    setOtp={setOtp}
                    SendVerificationEmail={SendVerificationEmail}
                    inputRefs={inputRefs}
                    otp={otp}
                    resendEnabled={resendEnabled}
                    setResendEnabled={setResendEnabled}
                    handleSubmit={handleVerifyOtp}
                    heading='Verify Your Email'
                    subheading="Enter the OTP sent to your email to verify your account."
                />
            </div>
        </div>
    );
};

export default VerifyNewUserPage;
