
const EmailValidation = async (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email) {
    throw new Error("Please Enter Your Email !");
  } else if (!emailRegex.test(email)) {
    throw new Error("Please Provide Correct Email Address!");
  }
  return true;
}
const PasswordValidation = async (password, confirmpassword) => {
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecial = /[@!#$%&*?\s]/.test(password);


  if (!password) {
    throw new Error("Please Enter Password!");
  }
  else if (password.length < 8) {
    throw new Error("Password Lenght Must Be 8 Character!");
  }
  else if (!hasUppercase) {
    throw new Error("Password Must Have One UpperCase Character!");
  }
  else if (!hasLowercase) {
    throw new Error("Password Must Have One LowerCase Character!");
  }
  else if (!hasNumber) {
    throw new Error("Password Must Have One Number!");
  }
  else if (!hasSpecial) {
    throw new Error("Password Must Have One Special Character!");
  }
  // else if(password !== confirmpassword){
  //   throw new Error("Confirm Password Does not Match!!");
  // }

  return true;
}

const OnlyTextValidation = async (Fields, details) => {
  const regex = /^[A-Za-z\s]*$/;
  for (let field of Fields) {
    if (!regex.test(details[field])) {
      throw new Error(
        `${field.charAt(0).toUpperCase() + field.slice(1)} can only contain letters and spaces!`
      );
    }
  }
  return true;
}
const EmptyFieldValidation = async (requiredFields, details) => {
  for (let field of requiredFields) {
    if (!details[field]) {
      throw new Error(`${field.charAt(0).toUpperCase() + field.slice(1)} is required. Please provide the ${field}.`);
    }

  }
  return true
}
const CheckedValidation = async (isChecked) => {
  if (!isChecked) {
    throw new Error("Please Check the Tickbox.");
  }
  return true
}
const OtpValidation = async (userotp) => {
  if (!userotp || userotp.trim().length === 0) {
    throw new Error("Please Enter Otp To Proceed!");
  }
  if (userotp.length !== 6) {
    throw new Error("Please enter a 6-digit OTP!");
  }
  if (!/^\d{6}$/.test(userotp)) {
    throw new Error("OTP must contain only digits!");
  }
  return true;
};

export { PasswordValidation, EmailValidation, OtpValidation, EmptyFieldValidation, OnlyTextValidation, CheckedValidation };