import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const CreatePayment = () => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (!location.state?.paymentUrl) {
            navigate('/upgrade-plan');
        } else {
            const newWindow = window.open(location.state.paymentUrl, '_self');
            if (!newWindow) {
                window.location.href = location.state.paymentUrl;  // Fallback for pop-up blockers
            }
        }
    }, [location.state, navigate]);

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <p className="text-lg font-semibold">Redirecting to payment...</p>
        </div>
    );
};

export default CreatePayment;




// import React, { useState, useEffect } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import LoadingSpinner from "../utils/LoadingSpinner";
// import Modal from "../utils/Modal";

// const CreatePayment = () => {
//     const location = useLocation();
//     const navigate = useNavigate();
//     const [isLoading, setIsLoading] = useState(true);
//     const [isModalOpen, setIsModalOpen] = useState(false);

//     useEffect(() => {
//         // Check if paymentUrl is present in location.state
//         if (!location.state?.paymentUrl) {
//             navigate('/upgrade-plan');
//         }
//     }, [location.state, navigate]);

//     const handleCancel = () => {
//         setIsModalOpen(true);
//     };

//     const handleConfirmCancel = () => {
//         setIsModalOpen(false);
//         navigate('/upgrade-plan');
//     };

//     return (
//         <div className="flex justify-center items-center min-h-screen bg-gray-100 md:mt-10">
//             {isLoading && (
//                 <div className="absolute z-50 p-5 top-1/4 left-1/2 -translate-x-1/2 -translate-y-1/2">
//                     <LoadingSpinner className="h-12 w-12 animate-spin z-50" />
//                 </div>
//             )}
//             <div className="w-full md:w-1/4 bg-white shadow-2xl p-6 rounded-t-2xl md:rounded-lg fixed bottom-0 md:static md:transform-none transition-transform ease-in-out duration-300 md:h-auto">
//                 {/* Close Button */}
//                 <div className="flex justify-end mb-4">
//                     <button onClick={handleCancel} className="text-gray-700">
//                         <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
//                         </svg>
//                     </button>
//                 </div>

//                 {/* Payment iframe */}
//                 <div className="w-full h-[70vh]">
//                     <iframe
//                         src={location.state?.paymentUrl}
//                         className="w-full h-full border-none"
//                         onLoad={() => setIsLoading(false)}
//                         title="Payment Gateway"
//                         allowpaymentrequest="true"
//                     />
//                 </div>

//                 {/* Warning message */}
//                 <div className="w-full bg-yellow-50 text-yellow-800 p-4 mt-4 text-center rounded-md">
//                     <p>Please do not press the back button or navigate away during the payment process.</p>
//                 </div>
//             </div>

//             {/* Confirmation Modal */}
//             <Modal
//                 isOpen={isModalOpen}
//                 onClose={() => setIsModalOpen(false)}
//                 onConfirm={handleConfirmCancel}
//                 title="Cancel Payment"
//                 message="Are you sure you want to cancel the payment?"
//             />
//         </div>
//     );
// };

// export default CreatePayment;