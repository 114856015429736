import { BASE_URL, API_ENDPOINTS, getHeaders } from './config';

export const refreshUserToken = async (refreshToken, sessionId, uid) => {
  try {
    const response = await fetch(`${BASE_URL}${API_ENDPOINTS.AUTH.REFRESH_TOKEN}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${refreshToken}`
      },
      body: JSON.stringify({ sessionId, uid })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.error || 'Token refresh failed');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Token refresh error:', error);
    throw error;
  }
};