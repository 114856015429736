import React, { useContext } from "react";
import { Dog, TriangleAlert, HeartPulse, ShieldPlusIcon, ClipboardPlus } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../App";
import { initiatePayment } from "../api/paymentApi";
import FAQ from "../Component/FAQ";
import Pressable from "../utils/Pressable";

const Section = () => {
  const navigate = useNavigate();
  const { User } = useContext(UserContext);

  const handlePaymentClick = async () => {
    if (!User) {
      navigate('/login', { state: { from: '/upgrade-plan' } });
      return;
    }

    try {
      const orderId = 'ORDER_' + Date.now();
      const amount = 1;

      console.log('Initiating payment with:', {
        orderId,
        amount,
        userId: User
      });

      const response = await initiatePayment(orderId, amount, User);
      console.log('Payment initiation response:', response); // Debug log
      
      if (response?.success && response?.paymentUrl) {
        navigate('/initiate-payment', { 
          state: { 
            paymentUrl: response.paymentUrl,
            orderId: orderId
          } 
        });
      } else {
        throw new Error('Invalid payment response structure');
      }
    } catch (error) {
      console.error('Payment initiation failed:', error);
      // Add user feedback here
      alert('Payment initiation failed. Please try again later.'); // Replace with proper toast notification
    }
  };

  return (
    <div className="flex flex-col md:flex-row-reverse items-center max-w-6xl w-full">
      <div className="md:w-1/2 text-left md:text-left space-y-4">
        <h2 className="text-2xl md:text-3xl font-bold text-slate-900">
          Petcare: Less Than a Coffee – INR 159!
        </h2>
        <p className="text-lg font-bold text-gray-700">Why Preventive Care?</p>
        <ul className=" text-gray-700 list-disc list-inside space-y-2">
          <li>Prevantive Care- Help catch illnesses before they become serious.</li>
          <li>Boost Your Pet's Happiness- Improve your pet's quality of life by focusing on wellness.</li>
          <li>Shield Your Pet and Your Wallet- Save you from unexpected and costly treatments.</li>
        </ul>
        <Pressable 
          onClick={handlePaymentClick}
          title="Get Started" 
          className="w-full md:w-1/2 mt-4 bg-[#7F55E0] text-xl text-white py-3 px-6 rounded-md transition duration-300 hover:bg-[#6A44C0]"
        />
      </div>
      <div className="md:w-1/2">
        <img src="./Images/welc_1.png" alt="Pet Care" className="mx-auto rounded-lg" />
      </div>
    </div>
  );
};

const UpgradePlan = () => {
  return (
    <div className="flex flex-col items-center p-4 pt-20 space-y-8 bg-slate-50 min-h-screen ">
        <Section/>
      {/* second section */}
      <div className="flex flex-col md:flex-row items-center max-w-6xl w-full">
        <div className="md:w-1/2 text-left space-y-4">
          <h2 className="text-2xl md:text-3xl font-bold text-slate-900">
            AI-powered preventive care keeps your pet healthy and detects issues early.
          </h2>
          <p className="text-lg font-regular text-gray-700">
            Your pet may seem healthy, but hidden dangers lurk. Poor diet triggers obesity, silent symptoms escalate, and late diagnoses lead to costly, less effective treatments.
          </p>
          <ul className="grid gap-4">
            {[
              { title: "Silent Weight Gain", desc: "Leads to obesity & diabetes." },
              { title: "Hidden Infections", desc: "Go unnoticed until they worsen." },
              { title: "Delayed Diagnosis", desc: "Costs more & risks your pet's health." },
            ].map((item, index) => (
              <li
                key={index}
                className="p-4 bg-white shadow-sm rounded-md border border-gray-200"
              >
                <strong className="text-gray-600">{item.title} –</strong> {item.desc}
              </li>
            ))}
          </ul>
        </div>
        <div className="md:w-1/2">
          <img src="./Images/welc_2.png" alt="Pet Care" className="mx-auto rounded-lg" />
        </div>
      </div>
      {/* thirdSection */}
      <div className="flex flex-col items-center max-w-6xl w-full gap-6">
        <h2 className="text-2xl md:text-3xl font-bold text-slate-900 text-center w-full">Your Pet's Health, Guided Every Step</h2>

        <div className="flex flex-col md:flex-row items-center w-full gap-6">
          {[
            {
              icon: <Dog size={48} color="#323842" />,
              title: "Create Pet Profile.",
              desc: "Quickly create and update your pet's profile for personalized care.",
            },
            {
              icon: <HeartPulse size={48} color="#323842" />,
              title: "Get AI Insights ",
              desc: " Access AI-driven health advice,Ensure your pet gets the best care.",
            },
            {
              icon: <ShieldPlusIcon size={48} color="#323842" />,
              title: " Worry Less, Love More",
              desc: "Stay ahead of health risks and spend more time with your pet",
            },
          ].map((item, index) => (
            <div
              key={index}
              className="h-[160px] flex flex-col items-center md:items-center p-2 bg-white shadow-sm rounded-md border border-gray-200 w-full md:w-1/3"
            >
              <span className="text-4xl">{item.icon}</span>
              <h3 className="text-xl font-bold text-gray-900 mt-2">{item.title}</h3>
              <p className="text-gray-700 text-center md:text-center mt-2 flex-grow">{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
      {/* fourth Section */}
      <div className="flex flex-col md:flex-row  items-center max-w-6xl w-full ">
      <div className="md:w-1/2">
          <img src="./Images/welc_4.png" alt="Pet Care" className="mx-auto rounded-lg" />
        </div>
        <div className="md:w-1/2 text-left space-y-4">
          <h2 className="text-2xl md:text-3xl font-bold text-slate-900">
            How Can VetiQ Help?
          </h2>
          <p className="text-lg font-regular text-gray-700">
          With proactive care, VetiQ ensures your pet stays ahead of potential health issues, preventing costly treatments and improving overall well-being.
          </p>
          <ul className="grid gap-4">
            {[
              { icon: <ClipboardPlus size={48} color="#7F55E0" />, title: "Accurate Pet Health Advice",},
              { icon: <HeartPulse size={48} color="#7F55E0" />, title: "Be proactive, not reactive!",},
              { icon: <TriangleAlert size={48} color="#7F55E0" />, title: "Early Risk Detection",  },
            ].map((item, index) => (
              <li
                key={index}
                className="flex p-4 bg-white shadow-sm rounded-md border border-gray-200 gap-8 items-center"
              >
                <span >{item.icon}</span> <strong className=" md:text-2xl text-gray-600">{item.title}</strong>
              </li>
            ))}
          </ul>
        </div>
      </div>

       {/* FAQ Section */}
       <div className="flex flex-col items-center max-w-6xl w-full gap-6">
       <FAQ/>
       </div>
    </div>
  );
};

export default UpgradePlan;
