import { BASE_URL, API_ENDPOINTS, getHeaders } from './config';

export const getAllPetProfiles = async (uid) => {
  try {
    const response = await fetch(
      `${BASE_URL}${API_ENDPOINTS.PETS.GET_ALL.replace('${uid}', uid)}`, 
      {
        method: 'GET',
        headers: getHeaders(true)
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.message || 'Failed to fetch pet profiles');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching pet profiles:', error);
    throw error;
  }
};

export const addNewPetProfile = async (uid, petData) => {
  try {
    const response = await fetch(
      `${BASE_URL}${API_ENDPOINTS.PETS.ADD}`, 
      {
        method: 'POST',
        headers: await getHeaders(),
        body: JSON.stringify({ uid, ...petData })
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.message || 'Failed to add pet profile');
    }

    return await response.json();
  } catch (error) {
    console.error('Error adding pet profile:', error);
    throw error;
  }
}; 