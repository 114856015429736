import React, { useState } from 'react';
import { X } from 'lucide-react';
import Pressable from '../utils/Pressable';
import { addNewPetProfile } from '../api/petApi';
import LoadingSpinner from '../utils/LoadingSpinner';
import SnackBar from '../utils/SnackBar';

const PET_OPTIONS = {
  age: {
    Cat: ["kitten", "adult", "senior"],
    Dog: ["puppy", "adult", "senior"]
  },
  weight: {
    Cat: ["0-6Kg", "4-6Kg", "6+Kg"],
    Dog: ["0-10Kg", "10-25Kg", "25+Kg"]
  },
  vaccines: {
    common: ['Rabies', 'Parvovirus', 'Distemper'],
    Dog: ['Bordetella', 'Hepatitis', 'Influenza', 'Lyme Disease', 'Leptospirosis'],
    Cat: ['Feline Leukemia', 'FIV', 'Feline Infectious Peritonitis']
  }
};

const ModalHeader = ({ onClose }) => (
  <div className="flex justify-between items-center p-6 border-b border-gray-200">
    <h2 className="text-xl font-bold text-slate-900">Add New Pet Profile</h2>
    <Pressable 
      onClick={onClose} 
      className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
      title="Close"
    >
      <X size={24} />
    </Pressable>
  </div>
);

const PetTypeSelector = ({ petType, onSelect }) => (
  <div className="flex gap-4 mb-6">
    {['Cat', 'Dog'].map(type => (
      <Pressable
        key={type}
        onClick={() => onSelect(type)}
        className={`flex-1 py-2 rounded-lg border ${petType === type ? 'bg-[#7F55E0] text-white' : 'bg-white'}`}
        title={type}
      />
    ))}
  </div>
);

const BasicInfo = ({ formData, onChange }) => (
  <div className="space-y-4">
    <input
      type="text"
      placeholder="Name"
      className="w-full p-3 border rounded-lg"
      value={formData.petname}
      onChange={(e) => onChange('petname', e.target.value)}
    />
    <input
      type="text"
      placeholder="Breed"
      className="w-full p-3 border rounded-lg"
      value={formData.breed}
      onChange={(e) => onChange('breed', e.target.value)}
    />
  </div>
);

const OptionGroup = ({ title, options, selected, onSelect }) => (
  <div className="space-y-2">
    {title && <h3 className="font-semibold text-slate-900">{title}</h3>}
    <div className="flex gap-4">
      {options.map(option => (
        <Pressable
          key={option}
          onClick={() => onSelect(option)}
          className={`flex-1 py-2 rounded-lg border ${
            selected === option ? 'bg-[#7F55E0] text-white' : 'bg-white'
          }`}
          title={option}
        />
      ))}
    </div>
  </div>
);

const VaccineSelector = ({ vaccines, selectedVaccines, onToggle }) => (
  <div className="grid grid-cols-2 gap-2">
    {vaccines.map(vaccine => (
      <Pressable
        key={vaccine}
        onClick={() => onToggle(vaccine)}
        className={`p-2 rounded-lg border ${
          selectedVaccines.includes(vaccine) ? 'bg-[#7F55E0] text-white' : 'bg-white'
        }`}
        title={vaccine}
      />
    ))}
  </div>
);

const AddNewPetModal = ({ onClose, uid, onPetAdded }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSnackBarVisible, setSnackBarVisible] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [petType, setPetType] = useState('');
  const [formData, setFormData] = useState({
    petname: '',
    breed: '',
    pettype: '',
    gender: '',
    age: '',
    neutered: '',
    spayed: '',
    weight: '',
    vaccinated: '',
    vaccines: []
  });

  const showSnackbar = (message) => {
    setSnackMessage(message);
    setSnackBarVisible(true);
    setTimeout(() => {
      setSnackBarVisible(false);
      setSnackMessage('');
    }, 3000);
  };

  const handlePetTypeChange = (type) => {
    setPetType(type);
    setFormData({
      petname: '',
      breed: '',
      pettype: type,
      gender: '',
      age: '',
      neutered: type === 'Dog' ? '' : undefined,
      spayed: type === 'Cat' ? '' : undefined,
      weight: '',
      vaccinated: '',
      vaccines: []
    });
  };

  const handleInputChange = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleVaccineToggle = (vaccine) => {
    setFormData(prev => ({
      ...prev,
      vaccines: prev.vaccines.includes(vaccine)
        ? prev.vaccines.filter(v => v !== vaccine)
        : [...prev.vaccines, vaccine]
    }));
  };

  const handleSubmit = async () => {
    // Validate required fields
    if (!formData.petname || !formData.breed || !formData.pettype || !formData.gender || !formData.age || !formData.weight) {
      showSnackbar('Please fill in all required fields');
      return;
    }

    try {
      setIsLoading(true);
      
      // Prepare the request body
      const petData = {
        uid: uid,
        petData: {
          petname: formData.petname,
          breed: formData.breed,
          pettype: formData.pettype.toLowerCase(),
          gender: formData.gender,
          age: formData.age,
          weight: formData.weight,
          vaccinated: formData.vaccinated,
          vaccines: formData.vaccines,
          ...(formData.pettype === 'Dog' ? { neutered: formData.neutered } : { spayed: formData.spayed })
        }
      };

      await addNewPetProfile(uid, petData);
      showSnackbar('Pet profile added successfully!');
      onPetAdded();
      setTimeout(() => onClose(), 3000);
    } catch (error) {
      console.error('Error adding pet:', error);
      showSnackbar(error.message || 'Failed to add pet profile');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex md:items-center justify-center">
     {isSnackBarVisible &&  <SnackBar 
        isSnackBarVisible={isSnackBarVisible} 
        snackMessage={snackMessage}
      />}
      
      {isLoading && (
        <div className="absolute z-50 p-5 top-1/4 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <LoadingSpinner className="h-12 w-12 animate-spin z-50" />
        </div>
      )}

      <div className={`relative w-full bg-white shadow-2xl
        md:w-[600px] lg:w-[700px]
        md:rounded-2xl rounded-t-2xl
        flex flex-col
        md:my-8 md:mx-auto
        max-h-[90vh] md:max-h-[85vh]
        transition-all duration-300 ease-in-out
        ${window.innerWidth >= 768 ? 'translate-y-0' : 'translate-y-0 mt-auto'}
      `}>
        <ModalHeader onClose={onClose} />

        <div className="p-6 overflow-y-auto flex-1 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
          <PetTypeSelector petType={petType} onSelect={handlePetTypeChange} />

          {petType && (
            <div className="space-y-6 mt-6">
              <BasicInfo formData={formData} onChange={handleInputChange} />

              <OptionGroup
                title="Gender"
                options={['Male', 'Female']}
                selected={formData.gender}
                onSelect={(value) => handleInputChange('gender', value)}
              />

              <OptionGroup
                title="Age"
                options={PET_OPTIONS.age[petType]}
                selected={formData.age}
                onSelect={(value) => handleInputChange('age', value)}
              />

              <OptionGroup
                title={`Is your ${petType.toLowerCase()} ${petType === 'Dog' ? 'neutered' : 'spayed'}?`}
                options={['Yes', 'No']}
                selected={formData[petType === 'Dog' ? 'neutered' : 'spayed']}
                onSelect={(value) => handleInputChange(petType === 'Dog' ? 'neutered' : 'spayed', value)}
              />

              <OptionGroup
                title="Weight"
                options={PET_OPTIONS.weight[petType]}
                selected={formData.weight}
                onSelect={(value) => handleInputChange('weight', value)}
              />

              <OptionGroup
                title="Vaccinated"
                options={['Yes', 'No']}
                selected={formData.vaccinated}
                onSelect={(value) => handleInputChange('vaccinated', value)}
              />

              {formData.vaccinated === 'Yes' && (
                <div className="space-y-2">
                  <h3 className="font-semibold text-slate-900">Select Vaccines</h3>
                  <VaccineSelector
                    vaccines={[...PET_OPTIONS.vaccines.common, ...PET_OPTIONS.vaccines[petType]]}
                    selectedVaccines={formData.vaccines}
                    onToggle={handleVaccineToggle}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        <div className="p-6 border-t border-gray-200 bg-gray-50">
          <div className="flex justify-center md:justify-end">
            <Pressable
              onClick={handleSubmit}
              className="w-full md:w-auto bg-[#7F55E0] text-white px-8 py-3 rounded-lg hover:bg-[#6A44C0] transition-colors duration-200 flex justify-center items-center min-w-[200px]"
              title="Add Pet"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewPetModal; 