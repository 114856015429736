import React, {useEffect, useState, useContext} from "react";
import { Link, useLocation} from "react-router-dom";
import { Menu, X, Home, Star, HandCoins, ScanHeart } from "lucide-react";
import PoodlesLogo from "./PoodlesLogo";
import { UserContext } from "../App";
import { deleteUserData, getUserData } from '../localuserdata/indexedDB';
import { logoutUser } from '../api/authApi';

const Drawer = ({setIsOpen, isOpen}) => {
  const location = useLocation();
  const [firstRender, setFirstRender] = useState(true);
  const {User, setUser} = useContext(UserContext);

  const handleLogout = async () => {
    try {
      const userData = await getUserData(); // Get full user data for API call
      if (userData) {
        // Call logout API
        await logoutUser(userData.uid, userData.sessionId);
      }
      // Clear local data
      await deleteUserData();
      setUser(null);
      setIsOpen(false);
    } catch (error) {
      console.error('Logout error:', error);
      // Still clear local data even if API call fails
      await deleteUserData();
      setUser(null);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (!firstRender) {
      setIsOpen(false);
    }
    setFirstRender(false);
  }, [location.pathname]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 z-50 flex justify-start">
      <div className="w-3/4 sm:w-1/2 bg-white h-full shadow-2xl p-4 flex flex-col transform transition-transform ease-in-out duration-300">
        {/* Drawer Header */}
        <div className="flex justify-between items-center border-b pb-2">
          <div className="text-xl font-bold">
            <PoodlesLogo width={120} height={30}/>
          </div>
          <button onClick={() => setIsOpen(false)}>
            <X size={24} className="text-gray-700 hover:text-red-500" />
          </button>
        </div>
        
        {/* Drawer Body */}
        <div className="flex-1 mt-4">
          <nav className="flex flex-col gap-4">
            <Link to="/" onClick={() => setIsOpen(false)} className="flex items-center gap-2 text-gray-700 hover:text-blue-500 active:text-blue-700">
              <Home size={20} /> Home
            </Link>
            <Link to="/why-pet-prevantive-care" onClick={() => setIsOpen(false)} className="flex items-center gap-2 text-gray-700 hover:text-blue-500 active:text-blue-700">
              <ScanHeart size={28} />Why Prevantive Care?
            </Link>
            <Link to="/upgrade-plan" onClick={() => setIsOpen(false)} className="flex items-center gap-2 text-gray-700 hover:text-blue-500 active:text-blue-700">
              <HandCoins size={28} />Upgrade
            </Link>        
          </nav>
        </div>
        
        {/* Drawer Footer */}
        <div className="border-t pt-2 flex justify-around">
          {User ? (
            <button 
              onClick={handleLogout}
              className="bg-[#323842] text-white px-8 py-2 rounded-md hover:bg-[#7F55E0]"
            >
              Logout
            </button>
          ) : (
            <> 
              <Link to="/login" className="bg-[#323842] text-white px-8 py-2 rounded-md hover:bg-[#7F55E0]">
                LogIn
              </Link>
              <Link to="/signup" className="bg-[#7F55E0] text-white px-8 py-2 rounded-md">
                Sign Up
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Drawer;