import React from "react";

const Pressable = ({className,title,onClick,disabled,})=>{
    return(
        <button disabled={disabled} onClick={onClick} className={className}>
        {title}
      </button>
    )
}

export default Pressable;