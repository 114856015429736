import React,{useState} from "react";
import { useNavigate } from "react-router-dom";
import Pressable from "../utils/Pressable";
import FAQ from "../Component/FAQ";
import TextInput from "../utils/TextInput";
import LoadingSpinner from "../utils/LoadingSpinner";
import SnackBar from "../utils/SnackBar";
import { OnlyTextValidation,EmailValidation,EmptyFieldValidation,PasswordValidation } from "../Functions/Validations";
import { signupUser } from '../api/authApi';
import { encrypt } from "../Functions/encryption";
const SignUpPage = () => {
    const Navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSnackBarVisibale, setSnackbarVisible] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
    const [details,setDetails]=useState({
      fullname:"",
      email:"",
      mobile:"",
      password:""
     })
     function handleDetails(event) {
      const InputName = event.target.name;
      const NewValue = event.target.value;
      setDetails((prevValue) => {
        return {
          ...prevValue,
          [InputName]: NewValue,
        };
      });
    }
  const CreateNewUser = async () => {
    const { fullname, email, mobile, password } = details;
    try {
      setIsLoading(true);
      await EmptyFieldValidation(["fullname", "email", "mobile", "password"], details);
      await EmailValidation(email);
      
      const result = await signupUser( fullname, email, mobile, password );
      
      Navigate("/verify-new-user", {
        replace: true,
        state: { 
          nextScreen: "/ask-vetiq",
          email,
          uid: result.uid,
          userData: {
            accessToken:encrypt(result.accessToken),
            refreshToken: result.refreshToken,
            sessionId: result.sessionId,
            uid: result.uid
          }

        },
      });
    } catch (err) {
      setSnackbarVisible(true);
      setSnackMessage(err.message);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setSnackbarVisible(false);
      }, 3000);
    }
  };

  return (
    <div className="flex flex-col items-center p-4 pt-24 gap-4 bg-slate-50 min-h-screen ">
      <SnackBar isSnackBarVisibale={isSnackBarVisibale} snackMessage={snackMessage}/>
      {isLoading ? (
            <div className=" absolute z-50 p-5 top-1/4 left-1/2 -translate-x-1/2 -translate-y-1/2">
             <LoadingSpinner className={"h-12 w-12 animate-spin z-50 "} />
            </div>
          ) : null}
      {/* second section */}
      <div className="flex flex-col md:flex-row items-center max-w-6xl w-full gap-6 ">
        <div className="md:w-1/2 ">
          {/* Login Header */}
          <div className="pb-2 text-left">
            <h2 className="text-2xl font-bold  justify-center gap-2">Sign In To Your Account.</h2>
            <p className="text-lg  mt-2 text-stone-700">First time here?<a href="#"> You'll need to register. </a></p>
          </div>
          {/* Login Form Body */}
          <div className="mt-4">
          <TextInput required value={details.fullname} onChange={handleDetails}  name="fullname" label="FullName*" type="text" placeholder="Email" className="w-full p-3 border rounded mb-4 outline-none"/>
          <TextInput required value={details.mobile}   inputMode="numeric" pattern="[0-9]*" maxLength={10}onChange={handleDetails}  name="mobile" label="Mobile*" type="text" placeholder="Mobile" className="w-full p-3 border rounded mb-4 outline-none"/>
          <TextInput required value={details.email} onChange={handleDetails}  name="email" label="Email Address*" type="email" placeholder="Email" className="w-full p-3 border rounded mb-4 outline-none"/>
          <TextInput required value={details.password} onChange={handleDetails}  name="password" label="Password*" type="password" placeholder="Password" className="w-full p-3 border rounded mb-4 outline-none"/>
            <Pressable onClick={CreateNewUser} title="Create Account" className="w-full md:w-1/2 mt-4 bg-[#7F55E0] text-xl text-white py-3 px-6 rounded-md transition duration-300 hover:bg-[#6A44C0]"/>
          </div>
        </div>
        <div className="md:w-1/2 text-left space-y-6">
          <h2 className="text-2xl md:text-3xl font-bold text-slate-900">
            AI-powered preventive care keeps your pet healthy and detects issues early.
          </h2>
          <p className="text-lg font-regular text-gray-700">
            Your pet may seem healthy, but hidden dangers lurk. Poor diet triggers obesity, silent symptoms escalate, and late diagnoses lead to costly, less effective treatments.
          </p>
          <ul className="grid gap-4">
            {[
              { title: "Silent Weight Gain", desc: "Leads to obesity & diabetes." },
              { title: "Hidden Infections", desc: "Go unnoticed until they worsen." },
              { title: "Delayed Diagnosis", desc: "Costs more & risks your pet's health." },
            ].map((item, index) => (
              <li
                key={index}
                className="p-4 bg-white shadow-sm rounded-md border border-gray-200"
              >
                <strong className="text-gray-600">{item.title} –</strong> {item.desc}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* FAQ Section */}
      <div className="flex flex-col items-center max-w-6xl w-full gap-6">
        <FAQ />
      </div>
    </div>
  );
};

export default SignUpPage;
